import React from 'react'
import { Column } from '../layout/Column'
import { Section } from '../layout/Section'
import { developer } from '../../constants'
import { ContactForm } from '../ContactForm'
import Styles from '../../styles'

export const Contact = () => (
    <Section
        className="contact"
        id="contact"
        title="Contact"
        subtitle={`Have an interesting project or do you want to know more? Drop me an e-mail at ${developer.EMAIL} or fill out the form below.`}
        backgroundColor={Styles.colors.transparent}
    >
        <Column weight={8}>
            <ContactForm />
        </Column>
    </Section>
)
