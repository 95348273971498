import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'
import { media } from '../../media'
import styles from '../../styles'

interface NavItemProps {
    children: ReactNode
    anchorId: string
    onClick: () => void
}

const Container = styled(Link)`
    height: 50%;
    justify-self: center;
    display: flex;
    align-items: center;
    font-size: 120%;
    font-weight: 400;
    cursor: pointer;
    user-select: none;
    text-transform: uppercase;
    padding-bottom: 5px;

    & + & {
        margin-left: 3em;

        ${media.hd} {
            margin-left: 3vw;
        }
    }

    &.active,
    :hover {
        ${media.phone} {
            padding-bottom: 0;
            border: none;
            background-color: ${({ theme }) => theme.colors.highlight};
        }
        border-top: 1px solid transparent;
        border-bottom: 1px solid ${({ theme }) => theme.colors.text};
    }
    ${media.phone} {
        line-height: 200%;
        padding: ${({ theme }) => theme.dimensions.sticky.padding};
        & + & {
            margin-left: 0;
        }
    }
`

const NavItem = ({ children, anchorId, onClick }: NavItemProps) => {
    return (
        <Container
            activeClass="active"
            to={anchorId}
            spy
            smooth
            offset={-styles.dimensions.sticky.height}
            duration={500}
            onClick={onClick}
        >
            {children}
        </Container>
    )
}

export default NavItem
