import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import Button from './hoverable/Button'
import { developer } from '../constants'

interface FormData {
    name: string
    email: string
    message: string
}

const Form = styled.form`
    display: grid;
    grid-gap: 1em 0;
    width: 100%;

    > * {
        border-radius: ${({ theme }) => theme.dimensions.borderRadius};
        border: none;
        padding: 1em;
    }
`

const Input = styled.input`
    height: 2.5em;
    display: flex;
`

const Message = styled.textarea`
    height: 150px;
    resize: none;
`

const Submit = styled(Button)``

const FeedbackMessage = styled.span<{ wasSuccess: boolean }>`
    color: ${({ wasSuccess }) => (wasSuccess ? 'green' : 'red')};
    padding: 0.2em 0;
`

const generateFieldErrors = (name: string, errors: any) => {
    const errorType = errors[name].type
    let message
    switch (errorType) {
        case 'required':
            message = 'This is a required field'
            break
        case 'minLength':
            message = `This ${name} is too short`
            break
        case 'maxLength':
            message = `This ${name} is too long`
            break
        default:
            message = `This ${name} is invalid, please try again`
            break
    }

    return <FeedbackMessage wasSuccess={false}>{message}</FeedbackMessage>
}

const generateSendResponse = (wasSuccess: boolean) => {
    const message = wasSuccess
        ? 'Your mail was sent successfully'
        : `Something went wrong, please contact me through my email adress directly.`

    return <FeedbackMessage wasSuccess={wasSuccess}>{message}</FeedbackMessage>
}

export const ContactForm = () => {
    const [validInput, setValidInput] = useState<boolean>(false)
    const [sending, setSending] = useState<boolean>(false)
    const [successSent, setSuccessSent] = useState<boolean>(false)
    const [hasSent, setHasSent] = useState<boolean>(false)
    const { register, handleSubmit, errors, reset } = useForm<FormData>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
    })

    const onChange = () => {
        const validInput = Object.keys(errors).length === 0
        setValidInput(validInput)
        setHasSent(false)
        console.log('setting valid input to', validInput)
    }

    const { NAME, EMAIL } = developer
    const onSubmit = async (data: FormData) => {
        if (sending) {
            return
        }
        setSending(true)

        const { name, email, message } = data
        const trimmedName = name.trim()
        const trimmedEmail = email.trim()
        const trimmedMessage = message.trim()

        const response = await fetch(
            'https://api.sendinblue.com/v3/smtp/email',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'api-key': process.env.REACT_APP_SENDIN_BLUE_KEY as string,
                },
                body: JSON.stringify({
                    to: [
                        {
                            email: EMAIL,
                            name: NAME,
                        },
                    ],
                    subject: `Contactform via Vondelcode.com: ${trimmedName}`,
                    sender: {
                        name: trimmedName,
                        email: trimmedEmail,
                    },
                    htmlContent: `<html><head></head><body><p>from: ${trimmedName} (${trimmedEmail})</p><p>${trimmedMessage.replace(
                        /(\r\n|\n|\r)/gm,
                        '<br/>'
                    )}</p></body></html>`,
                }),
            }
        )
        const body = await response.json()
        const status = response.status

        console.log('body', body, 'status', status, 'errors', errors)

        setSuccessSent(status === 200 || status === 201)
        setHasSent(true)

        if (Object.values(errors).length === 0) {
            reset()
        }

        setTimeout(() => {
            setSending(false)
        }, 1000)
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Input
                type="text"
                placeholder="Name"
                name="name"
                onChange={onChange}
                ref={register({
                    required: true,
                    minLength: 5,
                    maxLength: 100,
                })}
            />
            {errors.name && generateFieldErrors('name', errors)}
            <Input
                type="text"
                placeholder="Email"
                name="email"
                onChange={onChange}
                ref={register({
                    required: true,
                    minLength: 5,
                    maxLength: 100,
                    pattern: /^\S+@\S+$/i,
                })}
            />
            {errors.email && generateFieldErrors('email', errors)}
            <Message
                name="message"
                placeholder="Message"
                onChange={onChange}
                ref={register({
                    required: true,
                    minLength: 20,
                    maxLength: 2000,
                })}
            />
            {errors.message && generateFieldErrors('message', errors)}
            <Submit disabled={!validInput} onClick={handleSubmit(onSubmit)}>
                Send
            </Submit>
            {hasSent && generateSendResponse(successSent)}
        </Form>
    )
}
