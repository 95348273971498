import React from 'react'
import styled from 'styled-components'
import { media } from '../media'

interface ParagraphProps {
    children: string
    className?: string
    textAlignCenter?: boolean
}

const Container = styled.p<{ textAlignCenter?: boolean }>`
    ${({ textAlignCenter }) => textAlignCenter && 'text-align: center;'};
    ${media.phone} {
        text-align: center;
    }
`

export const Paragraph = ({
    children,
    className = 'paragraph',
    textAlignCenter,
}: ParagraphProps) => (
    <Container textAlignCenter={textAlignCenter} className={className}>
        {children}
    </Container>
)
