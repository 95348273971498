import styled from 'styled-components'
import React from 'react'
import { Hoverable, HoverableProps } from './Hoverable'

const ButtonStyle = styled(Hoverable)`
    flex-direction: row-reverse;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.dimensions.borderRadius};
    font-weight: bold;
    font-size: 110%;
    padding: 0 25px;
    margin-top: 20px;
    user-select: none;
    color: ${({ theme }) => theme.colors.white};

    :hover svg {
        color: ${({ theme }) => theme.colors.white};
    }

    ${({ disabled, theme }) => {
        if (disabled) {
            return `background-color: ${theme.colors.disabled};`
        }
    }}

    svg {
        margin-left: 1em;
        margin-right: 0;
    }
`
const Button = ({
    icon,
    children,
    href,
    to,
    onClick,
    disabled,
}: HoverableProps) => (
    <ButtonStyle
        disabled={disabled}
        onClick={onClick}
        icon={icon}
        href={href}
        to={to}
        className="button"
    >
        {children}
    </ButtonStyle>
)

export default Button
