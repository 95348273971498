import React from 'react'
import { Paragraph } from '../Paragraph'
import styled from 'styled-components'
import { media } from '../../media'

interface TestimonialCardProps {
    avatar: string
    comment: string
    name: string
    position: string
}

const Avatar = styled.img`
    width: 100px;
    border-radius: 100%;
    margin-bottom: 2em;
`

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 500px;
`

const Quote = styled(Paragraph)`
    text-align: center;
    font-size: 110%;
    font-style: italic;
    margin-bottom: 2em;
`

const Reviewer = styled.h4`
    margin-top: 0;
    margin-bottom: 0.3em;

    ${media.phone} {
        text-align: center;
    }
`

const Position = styled(Paragraph)`
    margin-bottom: 0;
`

export const TestimonialCard = ({
    avatar,
    comment,
    name,
    position,
}: TestimonialCardProps) => (
    <Container>
        <Avatar src={avatar} alt={'testimonial'} />
        <Quote className={'quote'}>{`“${comment}”`}</Quote>
        <Reviewer>{name}</Reviewer>
        <Position textAlignCenter>{position}</Position>
    </Container>
)
