import React from 'react'
import { Column } from '../layout/Column'
import { Section } from '../layout/Section'
import Button from '../hoverable/Button'
import { Paragraph } from '../Paragraph'
import HireMeBanner from '../../assets/hireme_vondelpark_compressed.jpg'

export const HireMe = () => (
    <Section
        background={HireMeBanner}
        backgroundColor="transparent"
        className="hire-me"
        id="hire-me"
    >
        <Column weight={12} centerItems>
            <h3>Interested in hiring me?</h3>
            <Paragraph textAlignCenter>
                {
                    "Are you looking for a professional, communicative and assertive software engineer who can work across the stack? Let's get in touch!"
                }
            </Paragraph>
            <Button to="contact">Contact</Button>
        </Column>
    </Section>
)
