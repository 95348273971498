import React from 'react'
import styled from 'styled-components'
import { media } from '../media'

const SKILL_MARGIN = '0.5em'

interface TechStackProps {
    children: Array<string>
}

const Container = styled.ul`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    margin-left: -${SKILL_MARGIN};
    margin-right: -${SKILL_MARGIN};

    ${media.phone} {
        justify-content: center;
    }
`

const Skill = styled.img`
    padding: 0px 10px;
    margin: 5px ${SKILL_MARGIN};
    justify-content: center;
    display: inline-block;
    height: 30px;
    opacity: 0.8;
    transition: 0.3s all;

    ${media.phone} {
        margin: 5px 1em;
        padding: 0;
    }

    &:hover {
        opacity: 1;
    }
`

export const TechStack = ({ children }: TechStackProps) => (
    <Container>
        {children.map((tech: string, index: number) => (
            <Skill src={tech} key={index} />
        ))}
    </Container>
)
