import { Section } from '../layout/Section'
import React from 'react'
import { Column } from '../layout/Column'
import { Position } from '../Position'
import { faCode, faCog, faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

const SoftwareEngineerPosition = (
    <Position
        icon={faCog}
        title={'Software Engineer'}
        summary={
            'I am comfortable working on backend services such as APIs, microservices and databases.'
        }
        blocks={[
            {
                title: 'Technologies:',
                keywords: [
                    'Java',
                    'Spring',
                    'REST',
                    'AWS',
                    'SQL',
                    'Maven',
                    'CI/CD',
                ],
            },
        ]}
    />
)

const FullStackPosition = (
    <Position
        icon={faLayerGroup}
        title={'Full-stack Developer'}
        summary={
            'Working across the stack, I like to code from scratch while emphasizing concise code.'
        }
        blocks={[
            {
                title: 'Technologies:',
                keywords: [
                    'ReactJS',
                    'NodeJS',
                    'Microservices',
                    'GraphQL',
                    'NoSQL',
                    'Gradle',
                    'Webpack',
                ],
            },
        ]}
    />
)

const FrontEndPosition = (
    <Position
        icon={faCode}
        title={'Web Developer'}
        summary={
            'Developing modern website using popular libraries in Javascript.'
        }
        blocks={[
            {
                title: 'Technologies:',
                keywords: [
                    'ES2018',
                    'Typescript',
                    'Javascript',
                    'HTML',
                    'CSS3',
                    'Sass',
                    'Less',
                ],
            },
        ]}
    />
)

export const Positions = () => (
    <Container id="positions" className="positions" title={'Positions'}>
        <Column weight={4}>{SoftwareEngineerPosition}</Column>
        <Column weight={4}>{FullStackPosition}</Column>
        <Column weight={4}>{FrontEndPosition}</Column>
    </Container>
)

const Container = styled(Section)`
    padding-top: 0;
`
