import styled from 'styled-components'
import React from 'react'
import { Hoverable, HoverableProps } from './Hoverable'

const SocialIconStyle = styled(Hoverable)`
    width: 40px;
    border-radius: 5%;
    color: ${({ theme }) => theme.colors.text};

    :hover {
        background-color: ${({ theme }) => theme.colors.highlight};

        svg {
            color: ${({ theme }) => theme.colors.text};
        }
    }

    && svg {
        margin: auto;
        width: 50%;
        height: 50%;
    }
`

const SocialIcon = ({ icon, children, href }: HoverableProps) => (
    <SocialIconStyle icon={icon} href={href} className="social-icon">
        {children}
    </SocialIconStyle>
)

export default SocialIcon
