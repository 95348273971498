import React, {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import styled from 'styled-components'
import { useSwipeable } from 'react-swipeable'
import { Hoverable } from '../hoverable/Hoverable'
import { media } from '../../media'

interface CarouselProps {
    children: ReactNode
}

const Container = styled.div`
    width: 100%;
    overflow: hidden;
    user-select: none;
`

const ThumbsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
`

const Thumb = styled(Hoverable)`
    height: 20px;
    width: 20px;
    margin: 10px 10px;
    border-radius: 100%;
    border: 1px solid black;
`

const Slide = styled.div.attrs(({ style }) => ({
    style,
}))`
    min-width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 50px;

    ${media.phone} {
        padding: 0 5vw;
    }
`

const SliderContainer = styled.div`
    overflow: hidden;
    width: 600px;
    max-width: 90%;
    margin: auto;
`

const Slider = styled.div.attrs(({ style }) => ({
    style,
}))`
    display: flex;
    width: 100%;
    position: relative;
    backface-visibility: visible;
`

export const Carousel = ({ children }: CarouselProps) => {
    const [activeSlide, setActiveSlide] = useState(0)
    const [sliderLoc, setSliderLoc] = useState(0)
    const [sliderWidth, setSliderWidth] = useState(0)
    const [isSwiping, setIsSwiping] = useState(false)
    const [changedSlide, setChangedSlide] = useState(false)
    const childrenArray = React.Children.toArray(children)
    const myRef = useRef<HTMLDivElement>(null)

    const setSliderToPage = useCallback(
        (index: number) => {
            requestAnimationFrame(() => {
                const sliderPage = Math.min(
                    Math.max(index, 0),
                    childrenArray.length - 1
                )
                const sliderLoc = sliderPage * sliderWidth
                setSliderLoc(sliderLoc)
            })
        },
        [sliderWidth, childrenArray.length]
    )

    const calculateDelta = (delta: number) => {
        const outOfBoundsLimitedDelta = Math.min(
            Math.max(0, delta),
            sliderWidth * (childrenArray.length - 1)
        )

        const direction = Math.sign(delta - sliderLoc)
        const activeSliderLoc = activeSlide * sliderWidth
        const pageLimitedDelta =
            Math.abs(activeSliderLoc - delta) >= sliderWidth
                ? direction * sliderWidth + activeSliderLoc
                : outOfBoundsLimitedDelta

        return pageLimitedDelta
    }

    const calculateActiveSlide = useCallback(() => {
        const activeSlide = Math.ceil(
            (sliderLoc - sliderWidth / 2) / sliderWidth
        )

        return Number.isNaN(activeSlide) ? 0 : activeSlide
    }, [sliderLoc, sliderWidth])

    useEffect(() => {
        requestAnimationFrame(() => {
            const newSlide = calculateActiveSlide()
            setActiveSlide(newSlide)

            if (newSlide !== activeSlide) {
                setChangedSlide(true)
            }
            if (!isSwiping && sliderWidth > 0) {
                setSliderToPage(newSlide)
            }
        })
    }, [
        sliderLoc,
        isSwiping,
        sliderWidth,
        setSliderToPage,
        activeSlide,
        calculateActiveSlide,
    ])

    useEffect(() => {
        function updateWidth() {
            const width = myRef.current ? myRef.current.offsetWidth : 0
            if (width !== sliderWidth) {
                setSliderWidth(width)
                setSliderToPage(calculateActiveSlide())
            }
        }

        window.addEventListener('resize', updateWidth)
        updateWidth()
        return () => window.removeEventListener('resize', updateWidth)
    }, [calculateActiveSlide, setSliderToPage, sliderWidth])

    const slides = childrenArray.map((child, index) => (
        <Slide key={index}>{child}</Slide>
    ))

    const thumbs = useMemo(() => {
        return childrenArray.map((child, index) => {
            const onClick = () => {
                setSliderToPage(index)
            }

            return (
                <Thumb
                    className={index === activeSlide ? 'active' : ''}
                    onClick={onClick}
                    key={index}
                />
            )
        })
    }, [activeSlide, childrenArray, setSliderToPage])

    const handlers = useSwipeable({
        onSwiped: () => {
            requestAnimationFrame(() => {
                setIsSwiping(false)
                setChangedSlide(false)
            })
        },
        onSwiping: (eventData) => {
            requestAnimationFrame(() => {
                if (changedSlide) {
                    return
                }
                setIsSwiping(true)
                const swipeDelta =
                    eventData.deltaX * Math.min(eventData.velocity / 10, 0.1)
                const throttledDelta = calculateDelta(swipeDelta + sliderLoc)
                setSliderLoc(throttledDelta)
            })
        },
        delta: 20,
        preventDefaultTouchmoveEvent: false,
        trackTouch: true,
        trackMouse: true,
        rotationAngle: 0,
    })

    useEffect(() => {
        const current = myRef.current
        if (!handlers.ref !== undefined && current) {
            handlers.ref(current)
            console.log('Setting ref to', myRef.current)
        }
    }, [handlers, myRef])

    return (
        <Container>
            <SliderContainer {...handlers} ref={myRef}>
                <Slider
                    style={{
                        transform: `translate3d(-${sliderLoc}px, 0px, 0px)`,
                        transitionDuration: `${isSwiping ? 0 : 0.3}s`,
                    }}
                >
                    {slides}
                </Slider>
            </SliderContainer>
            <ThumbsContainer>{thumbs}</ThumbsContainer>
        </Container>
    )
}
