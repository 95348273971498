import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { normalize } from 'styled-normalize'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import theme from './styles'

const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  h1 {
    font-size: 3.8em;
    font-weight: 500;
  }
  
  h2 {
    font-size: 2.8em;
    font-weight: 500;
  }
  
  h3 {
    font-size: 1.8em;
    font-weight: 400;
  }
  
  h4 {
    font-size: 1.3em;
    font-weight: 300;
  }
  
  html, body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 100%;
    margin: 0;	
  }
  
  body {
    display: flex;
	justify-content: center;
  }
  
  #root {
		display: flex;
		flex: 1;
		flex-flow: column;
		width: 100%;
		height: 100%;
	}
	
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
 	display: none;
 	-webkit-appearance: none;
 	margin: 0;
 }
 
 ul, ol {
     list-style: none;
     padding: 0;
 }
  

  h1, h2, h3, h4, a {
     font-family: 'Roboto Slab';
     opacity: 0.8;
  }
  
  p, span, svg {
    margin-top: 0;
    line-height: 27px;
    opacity: 0.8;
  }
  
  a {
    text-decoration: none;
    color: ${theme.colors.text}
  }
  
  * {
    box-sizing: border-box;
  }
`

function noop() {}

if (process.env.NODE_ENV !== 'development') {
    console.log = noop
    console.warn = noop
    console.error = noop
}

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <App />
        <GlobalStyle />
    </ThemeProvider>,
    document.getElementById('root')
)

serviceWorker.unregister()
