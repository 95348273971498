import React from 'react'
import styled from 'styled-components'
import NavItem from './NavItem'
import { media } from '../../media'

const Container = styled.div<{ open: boolean }>`
    max-height: ${({ open }) => (open ? '50vh' : 0)};
    width: 100%;
    overflow-y: scroll;
    overflow-x: hide;
    transition: inherit;
    background-color: white;
    border-top: ${({ open }) => (open ? '1px' : 0)} solid black;
    display: none;
    ${media.phone} {
        display: block;
    }
`
interface HamburgerMenuProps {
    children: React.ReactElement<typeof NavItem>[]
    open: boolean
}

const HamburgerMenu = ({ children, open }: HamburgerMenuProps) => (
    <Container className="hamburger" open={open}>
        {children}
    </Container>
)

export default HamburgerMenu
