import { Section } from '../layout/Section'
import React from 'react'
import { Column } from '../layout/Column'
import about from '../../assets/fontijn_onder_transparant.png'
import styled from 'styled-components'
import { Paragraph } from '../Paragraph'
import KotlinIcon from '../../assets/tech-stack/kotlin.svg'
import SpringIcon from '../../assets/tech-stack/spring.svg'
import DockerIcon from '../../assets/tech-stack/docker.svg'
import ReactIcon from '../../assets/tech-stack/react.svg'
import TypescriptIcon from '../../assets/tech-stack/typescript.svg'
import GraphqlIcon from '../../assets/tech-stack/graphql.svg'
import { TechStack } from '../TechStack'

const Image = styled.div`
    background-image: url(${about});
    background-size: cover;
    background-position: top;
    width: 82%;
    height: auto;
    padding-bottom: 77%;
    background-repeat: no-repeat;
    border-radius: ${({ theme }) => theme.dimensions.borderRadius};
    background-color: inherit;
    opacity: 0.85;
}   
`

export const AboutMe = () => (
    <Section id="about" className="about-me">
        <Column weight={6}>
            <Image />
        </Column>
        <Column weight={6} title="About Me">
            <Paragraph>
                {`Hello, I’m Nico, a software engineer from the Netherlands. I'm experienced in full-stack development and currently working fully remote from Spain.`}
            </Paragraph>

            <Paragraph>
                {`I like creating simple solutions for complex problems. I love reusability and I always strive to maximize the potential of a single piece of code. Good code is both easily maintainable and intuitive to use for the end user.`}
            </Paragraph>

            <Paragraph>
                {`My main tech stack is Kotlin, Spring Boot and Docker on the backend and React, TypeScript and GraphQL on the frontend.`}
            </Paragraph>
            <TechStack>
                {[
                    KotlinIcon,
                    SpringIcon,
                    DockerIcon,
                    ReactIcon,
                    TypescriptIcon,
                    GraphqlIcon,
                ]}
            </TechStack>
        </Column>
    </Section>
)
