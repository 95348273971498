import React from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface PositionProps {
    icon: IconDefinition
    title: string
    summary: string
    blocks: Array<{
        title: string
        keywords: Array<string>
    }>
}

const Container = styled.div`
    box-shadow: 0px 10px 20px 4px #00000015;
    border-radius: ${({ theme }) => theme.dimensions.borderRadius};
    padding: 15% 10%;
    display: flex;
    flex-direction: column;
    font-size: 120%;
    flex: 1;
`

const PositionTitle = styled.h3`
    font-size: 1.4em;
    font-weight: 600;
    margin: 0 0 0.8em 0;
`
const SubTitle = styled.h4`
    font-weight: 400;
    margin-bottom: 0.8em;
`

const IconContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 2em;
`

const Icon = styled(FontAwesomeIcon)`
    font-size: 2.5em;
    color: ${({ theme }) => theme.colors.text};
`

const Keywords = styled.ul`
    line-height: 140%;
    margin: 0;
`

export const Position = ({ icon, title, summary, blocks }: PositionProps) => {
    return (
        <Container>
            <IconContainer>
                <Icon icon={icon} />
            </IconContainer>

            <PositionTitle>{title}</PositionTitle>
            <p>{summary}</p>
            {blocks.map((block, blockIndex) => {
                const { title, keywords } = block
                return (
                    <React.Fragment key={blockIndex}>
                        <SubTitle>{title}</SubTitle>
                        <Keywords>
                            {keywords.map((keyword, subIndex) => (
                                <li key={subIndex}>
                                    <span>{keyword}</span>
                                </li>
                            ))}
                        </Keywords>
                    </React.Fragment>
                )
            })}
        </Container>
    )
}
