import React from 'react'
import { HeroHeader } from './components/sections/HeroHeader'
import { AboutMe } from './components/sections/AboutMe'
import StickyHeader from './components/header/StickyHeader'
import { Positions } from './components/sections/Positions'
import { HireMe } from './components/sections/HireMe'
import { Testimonials } from './components/sections/Testimonials'
import { Contact } from './components/sections/Contact'
import { Footer } from './components/Footer'

const App = () => (
    <>
        <StickyHeader />
        <HeroHeader />
        <AboutMe />
        <Positions />
        <HireMe />
        <Testimonials />
        <Contact />
        <Footer />
    </>
)

export default App
