import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { breakpoints, media } from '../../media'
import { Title } from './Column'

interface SectionProps {
    children: ReactNode
    className: string
    id: string
    reverse?: boolean
    inline?: boolean
    centerItems?: boolean
    title?: string
    subtitle?: string
    background?: string
    backgroundColor?: string
}

const Container = styled.section<{
    background?: string
    backgroundColor?: string
}>`
    display: flex;
    padding: 7vh 5vw;
    flex-flow: column;
    flex: 1 0 auto;
    position: relative;
    background-color: ${({ theme, backgroundColor }) =>
        backgroundColor ? backgroundColor : theme.colors.white};
    z-index: 0;

    ${media.phone} {
        padding: 5vh 8vw;
    }

    ${({ background }) => {
        if (background) {
            return `
            z-index: -10;
            :after {
              content: '';
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: -1;
              background-image: url(${background});
              box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.8);
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }`
        }
    }}
`

const ContentRow = styled.div<{
    children: ReactNode
    className: string
    reverse?: boolean
    inline?: boolean
    centerItems?: boolean
}>`
    height: inherit;
    background-color: inherit;
    align-items: ${({ centerItems }) =>
        centerItems ? 'center' : 'flex-start'};
    justify-content: ${({ inline }) => (inline ? 'space-between' : 'center')};
    display: flex;
    max-width: ${breakpoints.wide}px;
    width: 100%;
    flex: 1 0 auto;
    margin: auto;

    ${media.phone} {
        flex-direction: ${({ reverse, inline }) => {
            if (inline) {
                return 'row'
            } else {
                return reverse ? 'column-reverse' : 'column'
            }
        }};
    }
`

const TitleRow = styled(ContentRow)`
    flex-direction: column;
    margin-bottom: 6em;
    flex: 0;
     
    ${media.phone} {
    margin-bottom: 2em;
    }
}
`

const SectionTitle = styled(Title)`
    margin: 0;
`

const SectionSubtitle = styled.h4`
    margin: 1.5em 0 0 0;
    text-align: center;
    font-size: 1em;
    max-width: 700px;
`

export const Section = ({
    children,
    className,
    id,
    reverse,
    centerItems,
    inline,
    title,
    subtitle,
    background,
    backgroundColor,
}: SectionProps) => (
    <Container
        id={id}
        className={className}
        background={background}
        backgroundColor={backgroundColor}
    >
        {title && (
            <TitleRow className={'title'} centerItems={true}>
                <SectionTitle>{title}</SectionTitle>
                {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
            </TitleRow>
        )}

        <ContentRow
            className={'row'}
            reverse={reverse}
            centerItems={centerItems}
            inline={inline}
        >
            {children}
        </ContentRow>
    </Container>
)
