import React from 'react'
import { Column } from '../layout/Column'
import { Section } from '../layout/Section'
import { TestimonialCard } from '../carousel/TestimonialCard'
import Sander from '../../assets/testimonials/sander.jpeg'
import Jeroen from '../../assets/testimonials/jeroen.jpg'
import Leo from '../../assets/testimonials/leo.jpg'
import { Carousel } from '../carousel/Carousel'

export const Testimonials = () => (
    <Section className="testimonials" id="testimonials" title="Testimonials">
        <Column weight={12} centerItems>
            <Carousel>
                <TestimonialCard
                    avatar={Jeroen}
                    comment={`Nico is an eccentric software developer that really knows how to figure out the client's needs. He helped me design an application that also works for the end-user and not just from a technical point-of-view. We had much fun along the way, I'd recommend him!`}
                    name={'Jeroen Schoemaker'}
                    position={'Business Controller, Samsung'}
                />
                <TestimonialCard
                    avatar={Leo}
                    comment={
                        'Nico is determined to build the best solution for the problem at hand while keeping an eye on maintainability and performance. He is confident and versatile, giving him the shape of a good full stack developer. His communication skills are sharp and efficient among the team. I would gladly work with Nico in the future if the opportunity arises.'
                    }
                    name={'Léo Schneider'}
                    position={'Software Engineer, Ximedes'}
                />
                <TestimonialCard
                    avatar={Sander}
                    comment={
                        'Nico is a robust developer and an innovator at heart. He was able to carry out tasks independently and swiftly, often improving code on the go. His fine communication skills and good sense of humor made him a great fit in our team. Although we are sad to see him go, we wish him all the best in his future employment.'
                    }
                    name={'Sander Tigelaar'}
                    position={'Head of development and IT, LevelUp Group'}
                />
            </Carousel>
        </Column>
    </Section>
)
