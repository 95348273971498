import React from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { media } from '../../media'
import styles from '../../styles'
import { scroller } from 'react-scroll'

export interface HoverableProps {
    className?: string
    icon?: IconDefinition
    children?: string
    href?: string
    to?: string
    disabled?: boolean
    onClick?: () => void
}

const HoverableIcon = styled(FontAwesomeIcon)`
    transition: inherit;
    margin-right: 1em;
`

const Container = styled.a<{ to?: string; disabled?: boolean }>`
    opacity: 0.9;
    display: inline-flex;
    align-items: center;
    transition: 0.2s;
    height: 35px;
    color: ${({ theme }) => theme.colors.text};
    cursor: ${({ href, to, onClick, disabled }) =>
        (href || to || onClick) && !disabled ? 'pointer' : 'normal'};

    ${({ href, to, disabled, theme }) => {
        if (!disabled) {
            return `:hover, &.active {
        background-color: ${theme.colors.text};
        svg {
            color: ${href || to ? theme.colors.primary : theme.colors.text};
         }}`
        }
    }}

    ${media.tablet} {
        margin: 0 auto;
    }
`

export const Hoverable = ({
    icon,
    children,
    href,
    to,
    onClick,
    className,
    disabled,
}: HoverableProps) => {
    const scrollHandler = () =>
        to &&
        scroller.scrollTo(to, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -styles.dimensions.sticky.height,
        })

    const clickHandler = disabled ? () => {} : onClick || scrollHandler

    return (
        <Container
            disabled={disabled}
            to={to}
            href={to ? undefined : href}
            className={className}
            onClick={clickHandler}
            target={to ? undefined : '_blank noopener noreferrer'}
        >
            {icon && <HoverableIcon icon={icon} />}
            {children && <div>{children}</div>}
        </Container>
    )
}
