import React from 'react'
import styled from 'styled-components'
import HeroImage from '../../assets/hero_vondelpark_compressed.jpg'
import PhotoNico from '../../assets/me_v3.jpg'
import { media } from '../../media'
import { faEnvelope, faMapPin } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { developer, social } from '../../constants'
import { Section } from '../layout/Section'
import { Column } from '../layout/Column'
import IconRow from '../hoverable/IconRow'
import SocialIcon from '../hoverable/SocialIcon'

const Container = styled(Section)`
    background-image: url(${HeroImage});
    background-color: transparent;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 80%;
    box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.8);

    align-items: center;
    display: flex;

    ${media.phone} {
        min-height: 100%;

        // Fix for mobile sticky header overlapping image
        .row {
            padding-top: 15%;
        }
    }

    ${media.tablet} {
        text-align: center;
        justify-content: center;
        background-position: 41%;
    }

    // for rotated devices
    ${media.horizontal} {
        min-height: 100%;
    }
`

const Introduction = styled.div`
    font-size: 120%;
    ${media.phone} {
        font-size: 80%;
    }
`

const Name = styled.h2`
    margin: 1em 0 0 0;
    ${media.wide} {
        margin: 0;
    }
`

const JobTitle = styled.h3`
    margin: 0 0 0.8em 0;
`

const VerticalList = styled.ul`
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`

const HorizontalList = styled.ul`
    grid-template-columns: repeat(auto-fit, minmax(40px, 50px));
    display: grid;
    margin: 0;
    ${media.tablet} {
        justify-content: center;
    }
`

const PersonalImage = styled.div`
    background-image: url(${PhotoNico});
    background-size: cover;
    background-position: top;
    filter: grayscale(10%); // desaturate avatar a bit
    width: 80%;
    height: auto;
    padding-bottom: 80%;
    background-repeat: no-repeat;
    border-radius: 50%;
    box-shadow: 5px 9px 8px 3px #0000003d;
    margin-left: auto;
     
    ${media.tablet} {
        text-align: center;
        justify-content: center;
            margin: 5vw auto;
    }
    
         
    ${media.phone} {
        margin: auto auto 0 auto;
    }
}   
`

const DetailRow = styled(IconRow)`
    && svg {
        width: 1em;
    }
`

export const HeroHeader = () => {
    const { NAME, JOB_TITLE, EMAIL, LOCATION } = developer
    const { LINKED_IN, GITHUB } = social
    // const phoneHref = PHONE_NUMBER.replace(/ /g, '').replace('+', '00')
    const locationHref = LOCATION.replace(/,/g, '').replace(' ', '+')

    return (
        <Container id="home" className="hero-header" reverse centerItems>
            <Column weight={6} centerContent>
                <div>
                    <Introduction>
                        <Name>{NAME}</Name>
                        <JobTitle>{JOB_TITLE}</JobTitle>
                    </Introduction>
                    <VerticalList>
                        <DetailRow
                            icon={faMapPin}
                            href={`https://maps.google.com/?q=${locationHref}`}
                        >
                            {LOCATION}
                        </DetailRow>
                        <DetailRow icon={faEnvelope} href={`mailto:${EMAIL}`}>
                            {EMAIL}
                        </DetailRow>
                        {/*<DetailRow icon={faPhoneAlt} href={`tel:${phoneHref}`}>*/}
                        {/*    {PHONE_NUMBER}*/}
                        {/*</DetailRow>*/}
                    </VerticalList>
                    <HorizontalList>
                        <SocialIcon icon={faLinkedinIn} href={LINKED_IN} />
                        <SocialIcon icon={faGithub} href={GITHUB} />
                    </HorizontalList>
                </div>
            </Column>
            <Column weight={6} centerContent>
                <PersonalImage />
            </Column>
        </Container>
    )
}
