import styled from 'styled-components'
import React from 'react'
import { Hoverable, HoverableProps } from './Hoverable'

const IconRowStyle = styled(Hoverable)`
    :hover {
        background-color: transparent;
    }
`

const IconRow = ({
    icon,
    children,
    href,
    className = 'icon-row',
}: HoverableProps) => (
    <IconRowStyle icon={icon} href={href} className={className}>
        {children}
    </IconRowStyle>
)

export default IconRow
