export default {
    colors: {
        text: 'black',
        white: 'white',
        disabled: 'grey',
        primary: 'rgba(54, 54, 56, 1);',
        secondary: '#B5B5B7',
        transparent: 'transparent',
        highlight: 'rgba(54, 54, 56, 0.15);',
    },
    dimensions: {
        sticky: {
            height: 70,
            padding: '0 5vw',
        },
        borderRadius: '5px',
    },
}
