const customMediaQuery = (maxWidth: number) =>
    `@media (max-width: ${maxWidth}px)`

export const breakpoints = {
    hd: 1920,
    wide: 1140,
    desktop: 922,
    tablet: 768,
    phone: 576,
}

export const media = {
    custom: customMediaQuery,
    hd: customMediaQuery(breakpoints.hd),
    wide: customMediaQuery(breakpoints.wide),
    desktop: customMediaQuery(breakpoints.desktop),
    tablet: customMediaQuery(breakpoints.tablet),
    phone: customMediaQuery(breakpoints.phone),
    horizontal: `@media (max-height: ${breakpoints.desktop}px)`,
}
