import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { media } from '../../media'

const calculateWeight = (weight: number) => ((weight / 12) * 100).toFixed(6)
const calculatePadding = (weight: number) => ((weight / 12) * 8).toFixed(6)

interface ColumnProps {
    children: ReactNode
    weight: number
    title?: string
    className?: string
    centerContent?: boolean
    centerItems?: boolean
}

const Container = styled.div<ColumnProps>`
    display: flex;
    flex: 1 1 ${({ weight }) => calculateWeight(weight)}%;
    max-width: ${({ weight }) => calculateWeight(weight)}%;
    justify-content: ${({ centerContent }) =>
        centerContent ? 'center' : 'flex-start'};
    align-items: ${({ centerItems }) => (centerItems ? 'center' : 'baseline')};
    height: 100%;
    padding: 0 ${({ weight }) => calculatePadding(weight)}%;
    width: 100%;
    flex-direction: column;
    background-color: inherit;

    ${media.wide} {
        padding: 0 3vw;
    }

    ${media.phone} {
        padding: 5vw 0;
        max-width: 100%;
        display: flex;
        align-items: center;
    }
`

export const Title = styled.h2`
    font-size: 2.5em;
    margin: 0.5em 0;
    font-weight: 500;
`

export const Column = ({
    children,
    weight,
    title,
    className,
    centerContent,
    centerItems,
}: ColumnProps) => (
    <Container
        weight={weight}
        className={className ? className : 'column'}
        centerContent={centerContent}
        centerItems={centerItems}
    >
        {title && <Title>{title}</Title>}
        {children}
    </Container>
)
