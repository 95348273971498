import React from 'react'
import styled from 'styled-components'
import IconRow from './hoverable/IconRow'

export const Footer = () => {
    const year = new Date().getFullYear()
    return (
        <Container>
            {`© ${year}, Nico van Duuren — A tribute to`}
            <Anchor href="https://en.wikipedia.org/wiki/Vondelpark">
                Vondelpark
            </Anchor>
        </Container>
    )
}

const Container = styled.div`
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Anchor = styled(IconRow)`
    margin-left: 4px;
    margin-right: 0;
`
