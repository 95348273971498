export const developer = {
    NAME: 'Nico van Duuren',
    JOB_TITLE: 'Software Engineer',
    EMAIL: 'nicovanduuren@gmail.com',
    LOCATION: 'Madrid, Spain',
}

export const social = {
    LINKED_IN: 'https://www.linkedin.com/in/nicovanduuren/',
    GITHUB: 'https://github.com/Nikecow',
}
