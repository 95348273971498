import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import fountain from '../../assets/fountain.png'
import NavItem from './NavItem'
import { breakpoints, media } from '../../media'
import Hamburger from './Hamburger'
import HamburgerMenu from './HamburgerMenu'

const Logo = styled.div`
    height: 35px;
    width: 35px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${fountain});
`

const Container = styled.div<{
    children: ReactNode
    sticky: boolean
    centerItems: boolean
    inline: boolean
}>`
    width: 100%;
    padding: ${({ theme }) => theme.dimensions.sticky.padding};
    height: ${({ theme }) => theme.dimensions.sticky.height}px;

    background-color: ${({ theme, sticky }) =>
        sticky ? theme.colors.white : 'transparent'};
    transition: inherit;
    box-shadow: ${({ sticky }) =>
        sticky
            ? '0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)'
            : 'none'};
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: ${breakpoints.wide}px;
    height: 100%;
    margin: auto;
    padding: 0 2%;
`

const Title = styled.h4`
    margin: 0;
    margin-left: 0.5em;
    user-select: none;
    font-weight: 400;
    text-transform: uppercase;
`

const Banner = styled.a`
    display: flex;
    height: inherit;
    align-items: center;
    cursor: pointer;
`

const NavItemsContainer = styled.div`
    display: flex;

    ${media.phone} {
        display: none;
    }
`

const LogoContainer = styled.div`
    align-items: flex-start;
`

const HamburgerContainer = styled.div`
    display: none;
    align-items: flex-end;

    ${media.phone} {
        display: flex;
    }
`

const Sticky = styled.div<{
    children: ReactNode
    sticky: boolean
    className: string
    hideSticky: boolean
}>`
    transition: 0.3s all;
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;

    ${media.phone} {
        ${({ hideSticky }) => {
            if (hideSticky) {
                return 'transform: translateY(-105%);'
            }
        }};
    }
`

const StickyHeader = () => {
    const [sticky, setSticky] = useState(false)
    const [collapseSticky, setCollapseSticky] = useState(false)
    const [lastScrollPosition, setLastScrollPosition] = useState(0)
    const [showBurger, setShowBurger] = useState(false)
    const burgerCloser = useCallback(() => showBurger && setShowBurger(false), [
        showBurger,
        setShowBurger,
    ])

    useEffect(() => {
        const scrollListener = () => {
            const { scrollY } = window
            setSticky(scrollY > 0)
            const collapse = scrollY >= lastScrollPosition && scrollY > 20
            if (Math.abs(scrollY - lastScrollPosition) > 100) {
                if (showBurger && collapse) {
                    setShowBurger(false)
                }

                setCollapseSticky(collapse)
                setLastScrollPosition(scrollY)
            }
        }
        window.addEventListener('scroll', scrollListener)

        return () => {
            window.removeEventListener('scroll', scrollListener)
        }
    }, [collapseSticky, lastScrollPosition, showBurger])

    useEffect(() => {
        window.addEventListener('click', burgerCloser)

        return () => {
            window.removeEventListener('click', burgerCloser)
        }
    }, [showBurger, burgerCloser])

    const links = [
        { title: 'Home', anchorId: 'home' },
        { title: 'About', anchorId: 'about' },
        { title: 'Positions', anchorId: 'positions' },
        { title: 'Contact', anchorId: 'contact' },
    ].map(({ title, anchorId }, index) => (
        <NavItem onClick={burgerCloser} key={index} anchorId={anchorId}>
            {title}
        </NavItem>
    ))

    return (
        <Sticky className="sticky" sticky={sticky} hideSticky={collapseSticky}>
            <Container
                id="sticky-header"
                className={'sticky-header'}
                sticky={sticky}
                inline
                centerItems
            >
                <Wrapper>
                    <LogoContainer>
                        <Banner href="#">
                            <Logo />
                            <Title>Vondelcode</Title>
                        </Banner>
                    </LogoContainer>
                    <NavItemsContainer className="sticky-nav-menu">
                        {links}
                    </NavItemsContainer>
                    <HamburgerContainer className="sticky-hamburger">
                        <Hamburger onClick={() => setShowBurger(!showBurger)} />
                    </HamburgerContainer>
                </Wrapper>
            </Container>
            <HamburgerMenu open={showBurger}>{links}</HamburgerMenu>
        </Sticky>
    )
}

export default StickyHeader
