import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    padding: 9px 8px;
    height: 32px;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
`

const Bar = styled.div`
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: black;
`

interface HamburgerProps {
    onClick: () => void
}

const Hamburger = ({ onClick }: HamburgerProps) => (
    <Container onClick={onClick}>
        <Bar />
        <Bar />
        <Bar />
    </Container>
)

export default Hamburger
